import { bech32m } from 'bech32';
/* eslint-disable import/default */
import chiaBls from 'chia-bls';
import clvmLib from 'clvm-lib';
import type { Profile } from '~/types/generated';
import { getApiRoot } from '~/utilities/api';
/* eslint-enable import/default */
import { STANDARD_PUZZLE_MOD } from '~/utilities/puzzles';

/* eslint-disable import/no-named-as-default-member */
export const { fromHex, toHex, hash256, concatBytes, JacobianPoint } = chiaBls;
export const { Program } = clvmLib;
/* eslint-enable import/no-named-as-default-member */

declare global {
  interface Window {
    chia: any;
    goby: any;
  }
}
type Amount = string | number;
type HexString = string;

interface Coin {
  parent_coin_info: string;
  puzzle_hash: string;
  amount: number;
}

interface CoinSpend {
  coin: Coin;
  puzzle_reveal: HexString;
  solution: HexString;
}

export interface SpendBundle {
  coin_spends: CoinSpend[];
  aggregated_signature: HexString;
}

interface TakeOfferParams {
  offer: string;
}

interface AssetAmount {
  assetId: string;
  amount: Amount;
}

interface TransferParams {
  to: string;
  amount: Amount;
  assetId: string;
  memos?: HexString[];
}

export interface CreateOfferParams {
  offerAssets: AssetAmount[];
  requestAssets: AssetAmount[];
}

interface AssetBalanceResp {
  confirmed: string;
  spendable: string;
  spendableCoinCount: number;
}

interface getAssetCoinsParams {
  type: string | null;
  assetId?: string | null;
  includedLocked?: boolean;
  offset?: number;
  limit?: number;
}

export interface SpendableCoin {
  coin: Coin;
  coinName: string;
  puzzle: string;
  confirmedBlockIndex: number;
  locked: boolean;
  lineageProof?: {
    parentName?: string;
    innerPuzzleHash?: string;
    amount?: number;
  };
}

export interface MetadataInfo {
  dataUris: string[];
  dataHash: string;
  metadataUris: string[];
  metadataHash: string;
  licenseUris: string[];
  licenseHash: string;
  editionTotal: number;
  editionNumber: number;
}

interface MintNFTParams {
  metadataInfo: MetadataInfo;
  didId: string;
  royaltyAddress?: string;
  royaltyPercentage?: number;
}

interface sendTransactionParams {
  spendBundle: SpendBundle;
}

// stay the same as [transaction_ack](https://docs.chia.net/docs/10protocol/wallet_protocol/#transaction_ack)
enum MempoolInclusionStatus {
  SUCCESS = 1, // Transaction added to mempool
  PENDING = 2, // Transaction not yet added to mempool
  FAILED = 3, // Transaction was invalid and dropped
}

interface TransactionResp {
  status: MempoolInclusionStatus;
  error?: string;
}

interface SignMessageParams {
  message: string;
  publicKey: string;
}

export interface Wallet {
  chainId(): Promise<string>;

  accounts(): Promise<string[]>;

  filterUnlockedCoins(params: { coinNames: string[] }): Promise<string[]>;

  walletSwitchChain(params: { chainId: string }): Promise<null>;

  connect(params?: { eager?: boolean }): Promise<boolean>;

  transfer(params: TransferParams): Promise<{
    id: string;
  }>; // @deprecated
  takeOffer(params: TakeOfferParams): Promise<{
    transaction_id: string;
    transaction: SpendBundle;
  }>; // @deprecated
  createOffer(params: CreateOfferParams): Promise<{
    transaction_id: string;
    offer: string;
  }>; // @deprecated
  getPublicKeys(params?: { limit?: number; offset?: number }): Promise<string[]>;

  signCoinSpends(params: { coinSpends: CoinSpend[] }): Promise<string>;

  estimateCoinSpendsFee(params: { coinSpends: CoinSpend[] }): Promise<string[]>;

  getAssetBalance(params: { type: string | null; assetId: string | null }): Promise<AssetBalanceResp>;

  getAssetCoins(params: getAssetCoinsParams): Promise<SpendableCoin[]>;

  sendTransaction(params: sendTransactionParams): Promise<TransactionResp[]>;

  signMessage(params: SignMessageParams): Promise<string>;

  mintNFT(params: MintNFTParams): Promise<SpendBundle>;
}

export const wallet = new Proxy({} as Wallet, {
  get(_, key) {
    return function (params: any) {
      return window.chia.request({ method: key, params });
    };
  },
});

const isGobyInstalled = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const { chia } = window;
  return Boolean(chia && chia.isGoby);
};

const timeout = (ms: number) =>
  new Promise<void>((_resolve, reject) => setTimeout(() => reject(new Error('Time out!')), ms));

export type ProfileWithCoin = Profile & { didCoin: SpendableCoin };

export const useGobyStore = defineStore('gobyStore', {
  state: () => ({
    account: undefined as string | undefined,
    publicKey: undefined as string | undefined,
    isGobyInstalled: undefined as boolean | undefined,
    profiles: undefined as ProfileWithCoin[] | undefined,
    nftCoins: undefined as SpendableCoin[] | undefined,
  }),
  getters: {
    counts: (state) => ({
      did: state.profiles?.length,
      nft: state.nftCoins?.length,
    }),
    profile: (state) => (profileId: string) => state.profiles?.find((p) => p.id === profileId),
    p2Puzzle: (state) => {
      if (!state.publicKey) return;
      const key = JacobianPoint.fromHex(state.publicKey, false);
      return STANDARD_PUZZLE_MOD.curry([Program.fromJacobianPoint(key)]);
    },
  },
  actions: {
    async connect(eager = false) {
      if (!eager) {
        const chainId = useRuntimeConfig().public.network;
        if ((await wallet.chainId()) !== chainId) {
          await wallet.walletSwitchChain({ chainId: chainId === 'testnet10' ? 'testnet11' : chainId });
        }
      }
      await wallet.connect({ eager });
      await this.initialize();
    },
    disconnect() {
      this.account = '';
      this.publicKey = '';
      this.isGobyInstalled = undefined;
    },
    async initialize() {
      if (process.client) {
        if (this.isGobyInstalled) {
          // Nothing to do
        } else if (isGobyInstalled()) {
          this.isGobyInstalled = true;

          const initAccount = async () => {
            this.publicKey = (await wallet.getPublicKeys())[0];
            await this.setAccount();
          };

          try {
            await Promise.race([wallet.connect({ eager: true }), timeout(10000)]);
            const chainId = useRuntimeConfig().public.network;
            if ((await wallet.chainId()) !== chainId) {
              await wallet.walletSwitchChain({ chainId: chainId === 'testnet10' ? 'testnet11' : chainId });
            }
            await initAccount();
          } catch (e) {
            this.account = '';
          }

          window.chia.on('accountChanged', () => initAccount());
          window.chia.on('chainChanged', () => window.location.reload());
        } else {
          this.isGobyInstalled = false;
          this.account = '';
        }
      }
    },
    async initializeAssets() {
      if (process.client && this.isGobyInstalled) {
        await Promise.all([this.loadNfts(), this.loadProfiles()]);
      }
    },
    async setAccount() {
      const accounts = await wallet.accounts();
      const account = accounts?.[0];
      this.account = account ? bech32m.encode('xch', bech32m.toWords(fromHex(account))) : '';
    },
    async loadNfts() {
      this.nftCoins = await wallet.getAssetCoins({ type: 'nft', limit: 1000 });
    },
    async loadProfiles() {
      const loadedProfiles = [];
      const didCoins = await wallet.getAssetCoins({ type: 'did', includedLocked: true, limit: 1000 });
      for (const didCoin of didCoins) {
        const didPuzzle = Program.deserializeHex(didCoin.puzzle);
        const [, curriedArgs] = didPuzzle.uncurry()!;
        const launcherId = curriedArgs[0].rest.first;
        let profile;
        try {
          profile = await $fetch<ProfileWithCoin>(`${getApiRoot()}/profile/${launcherId.toHex()}`);
          profile.didCoin = didCoin;
        } catch (e) {
          profile = {
            didCoin,
            id: launcherId.toHex(),
            encoded_id: bech32m.encode('did:chia:', bech32m.toWords(launcherId.toBytes())),
            verification_state: 0,
          };
        }
        loadedProfiles.push(profile);
      }
      this.profiles = loadedProfiles;
    },
    async refreshProfile(profile: Profile) {
      try {
        const loadedProfile = await $fetch<Profile>(`${getApiRoot()}/profile/${profile.id}`);
        if (this.profiles && loadedProfile) {
          const index = this.profiles?.findIndex((p) => p.id === profile.id);
          if (index !== undefined) {
            const didCoin = this.profiles[index].didCoin;
            this.profiles.splice(index, 1, { ...loadedProfile, didCoin });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    signCoinSpends(coinSpends: CoinSpend[]) {
      return wallet.signCoinSpends({ coinSpends });
    },
    takeOffer(offer: string) {
      return wallet.takeOffer({ offer });
    },
    async signMessage(message: string) {
      const publicKey = this.publicKey;
      if (!publicKey) {
        throw new Error('No public key');
      }
      const signature = await wallet.signMessage({ message, publicKey });
      return { publicKey, signature };
    },
    async getAssetCoins(type: 'did' | 'nft' | 'cat' | null, assetId?: string, includedLocked?: boolean) {
      const limit = 100;
      let offset = 0;
      const coins: SpendableCoin[] = [];
      while (true) {
        const assetCoins = await wallet.getAssetCoins({ type, assetId, includedLocked, limit, offset });
        coins.push(...assetCoins);
        if (assetCoins.length < limit) {
          break;
        }
        offset += limit;
      }
      return coins;
    },
  },
});
